import { DynamicImage, PrimaryButton } from 'components';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

interface CardProps {
  image: string;
  title: string;
  content: string;
}

interface ResultsCardsProps {
  buttonTitle?: string;
  list?: CardProps[];
  onContinue: () => void;
}

const Card: FC<CardProps> = ({ image, title, content }) => (
  <CardContainer>
    <CardTitleContainer>
      <CardImage src={image} alt="" />
      <CardTitle>{title}</CardTitle>
    </CardTitleContainer>
    <CardContent>{content}</CardContent>
  </CardContainer>
);

const ResultsCards: FC<ResultsCardsProps> = ({
  list,
  onContinue,
  buttonTitle,
}) => {
  const [loading, setLoading] = useState(false);

  const nextStep = () => {
    setLoading(true);
    onContinue();
  };

  return (
    <>
      <Container>
        {list &&
          list.map((item, index) => <Card key={`card-${index}`} {...item} />)}
      </Container>
      <ButtonContainer>
        <ContinueButtonStyled
          onClick={nextStep}
          loading={loading}
          disabled={loading}
        >
          {buttonTitle}
        </ContinueButtonStyled>
      </ButtonContainer>
    </>
  );
};

export default ResultsCards;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media ${tablet} {
    padding-bottom: 4rem;
  }
`;

const CardContainer = styled.div`
  border-radius: 0.625rem;
  border: 1px solid rgba(28, 28, 40, 0.08);
  background: #f9f6f4;
  width: 100%;
  padding: 1rem 0.75rem;
`;

const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const CardImage = styled(DynamicImage)`
  width: 1.5rem;
  height: 1.5rem;
`;

const CardTitle = styled.p`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const CardContent = styled.p`
  color: #28293d;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.014rem;
  padding-left: 2rem;
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: linear-gradient(
        1deg,
        rgba(250, 249, 247, 0.9) 1%,
        rgba(250, 249, 247, 0.9) 98.64%
      );
      filter: blur(4px);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: rgb(250, 249, 247);
      z-index: -1;
    }
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  max-width: 28.75rem;
  width: 100%;
  @media ${tablet} {
    margin: 0 auto;
  }
`;
