import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, PrimaryButton, Text } from 'components';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

interface ReviewsTypes {
  img: string;
  imgMob: string;
  buttonTitle: string;
  onContinue: () => void;
}

const ResultsChart: FC<ReviewsTypes> = ({
  img,
  imgMob,
  buttonTitle,
  onContinue,
}) => {
  const { isTablet } = useQuery();
  const [loading, setLoading] = useState(false);

  const nextStep = () => {
    setLoading(true);
    onContinue();
  };
  const quiz_answers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );

  return (
    <Container>
      <Box>
        <Img src={isTablet ? imgMob : img} alt="" />
      </Box>
      <ButtonContainer>
        <ContinueButtonStyled
          onClick={nextStep}
          loading={loading}
          disabled={loading}
        >
          {buttonTitle}
        </ContinueButtonStyled>
      </ButtonContainer>
    </Container>
  );
};

export default ResultsChart;

const Container = styled.div`
  max-width: 30.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
  }
`;

const ChartTitle = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.014rem;
  padding-bottom: 1rem;
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: linear-gradient(
        1deg,
        rgba(250, 249, 247, 0.9) 1%,
        rgba(250, 249, 247, 0.9) 98.64%
      );
      filter: blur(4px);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: rgb(250, 249, 247);
      z-index: -1;
    }
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  max-width: 28.75rem;
  width: 100%;
  @media ${tablet} {
    margin: 0 auto;
  }
`;

const Img = styled(DynamicImage)`
  width: 100%;
`;

const Box = styled.section`
  border-radius: 0.5rem;
  border: 1px solid rgba(28, 28, 40, 0.08);
  background: #f9f6f4;
  display: flex;
  width: 100%;
  padding: 1.29419rem 0.92444rem 1.01688rem 0.92444rem;
  flex-direction: column;
  align-items: center;
`;

const ListItem = styled(Text)`
  color: #8e909a;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.014rem;
  @media ${tablet} {
    font-size: 0.75rem;
    letter-spacing: -0.012rem;
  }
`;

const List = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.25rem 1rem 0;
`;
