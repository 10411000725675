import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, PrimaryButton } from 'components';

interface ResultsListProps {
  imgMob?: string;
  img?: string;
  before?: string;
  beforeList?: string[];
  after?: string;
  afterList?: string[];
  onContinue: () => void;
  buttonTitle: string;
}

const ResultsList: FC<ResultsListProps> = ({
  imgMob,
  img,
  before,
  beforeList,
  afterList,
  after,
  onContinue,
  buttonTitle,
}) => {
  const { isTablet } = useQuery();
  const [loading, setLoading] = useState(false);

  const nextStep = () => {
    setLoading(true);
    onContinue();
  };
  return (
    <>
      <ChartContainer>
        <ImgContainer>
          <StyledImg src={isTablet ? imgMob! : img!} alt="" />
        </ImgContainer>
        <SideContainer>
          <LeftSide>
            <LeftTitle dangerouslySetInnerHTML={{ __html: before! }} />
            <ListBox>
              {beforeList?.map((item: string, index: number) => (
                <ListItem
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              ))}
            </ListBox>
          </LeftSide>

          <RightSide>
            <RightTitle dangerouslySetInnerHTML={{ __html: after! }} />
            <ListBox>
              {afterList?.map((item: string, index: number) => (
                <ListItem
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              ))}
            </ListBox>
          </RightSide>
        </SideContainer>
      </ChartContainer>
      <ButtonContainer>
        <ContinueButtonStyled
          onClick={nextStep}
          loading={loading}
          disabled={loading}
        >
          {buttonTitle}
        </ContinueButtonStyled>
      </ButtonContainer>
    </>
  );
};

export default ResultsList;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: linear-gradient(
        1deg,
        rgba(250, 249, 247, 0.9) 1%,
        rgba(250, 249, 247, 0.9) 98.64%
      );
      filter: blur(4px);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: rgb(250, 249, 247);
      z-index: -1;
    }
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  max-width: 28.75rem;
  width: 100%;
  @media ${tablet} {
    margin: 0 auto;
  }
`;

const Title = styled.h1`
  color: #28293d;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  letter-spacing: -0.063rem;

  @media ${tablet} {
    font-size: 1.25rem;
    letter-spacing: -0.02rem;
  }
`;

const StyledImg = styled(DynamicImage)`
  height: 100%;
  width: 100%;
  max-width: 29.25rem;
  max-height: 8.625rem;

  @media ${tablet} {
    max-width: 19.375rem;
    max-height: 7.25rem;
  }
`;

const ImgContainer = styled.section`
  width: 100%;
  /* max-width: 25.0625rem; */
  /* margin: 1.5rem auto; */
  max-height: 8.125rem;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;

  @media ${tablet} {
    /* margin: 1.5rem 0.53rem;
    max-height: 7.25rem;
    max-width: 20.375rem; */
    margin-bottom: 2.75rem;
  }
`;

const StyledButton = styled(PrimaryButton)`
  border-radius: 6.25rem;
  background: #804da1;
  box-shadow: 0px 0px 1px 0px rgba(40, 41, 61, 0.04),
    0px 2px 4px 0px rgba(96, 97, 112, 0.16);
  width: 100%;
`;

const ChartContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.31rem 0.5rem 1rem;
  border-radius: 0.75rem;
  background: linear-gradient(
    90deg,
    rgba(255, 163, 148, 1) 50%,
    rgba(151, 223, 199, 1) 50%
  );
`;

const SideContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
`;

const LeftSide = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-right: 0;

  @media ${tablet} {
    /* padding: 1rem 0.5rem; */
  }
`;

const RightSide = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-left: none;
  margin-left: 1rem;

  @media ${tablet} {
    /* padding: 1rem 0.5rem; */
  }
`;

const LeftTitle = styled.p`
  color: #000;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.02rem;

  @media ${tablet} {
    font-size: 1rem;
    line-height: 140%;
    letter-spacing: -0.016rem;
  }
`;

const RightTitle = styled(LeftTitle)``;

const ListBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
`;

const ListItem = styled.p`
  color: #28293d;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.014rem;
  @media ${mobile} {
    span {
      display: block;
    }
  }
`;
