import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

interface ProgressProps {
  step: number;
  totalSteps: number;
}

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  height: 0.25rem;
  background: #fff;
  @media ${tablet} {
    margin: 0 1rem;
  }
`;

const ProgressBarContainer = styled.div<{ active: boolean }>`
  background: ${({ active }) => (active ? '#2FBE8F' : '#F9F6F4')};
  border-radius: 50px;
  flex-grow: 1;
  height: 100%;
  transition: background-color 0.25s;
`;

const ResultsProgress: FC<ProgressProps> = ({ step, totalSteps }) => {
  const currentStep = Math.min(Math.max(step, 1), totalSteps);

  return (
    <Container>
      {Array.from({ length: totalSteps }, (_, index) => (
        <ProgressBarContainer
          key={index + 1}
          active={index + 1 <= currentStep}
        />
      ))}
    </Container>
  );
};

export default ResultsProgress;
